import { AnyAction } from 'redux';

import { IBaseReducer, ILenderStatus } from '../../types/otherData';
import { getLendersStatusAction } from './actionTypes';

type Reducer = Omit<IBaseReducer, 'isLoaded' | 'isLoading'> & {
  data: Array<ILenderStatus>;
  lastUpdated: number;
};

const initialState: Reducer = {
  data: [],
  lastUpdated: 0,
};

export function lendersStatusReducer(state = initialState, action: AnyAction): Reducer {
  const { type } = action;
  switch (type) {
    case getLendersStatusAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getLendersStatusAction.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        lastUpdated: Date.now(),
        data: action.payload.data,
      };
    default:
      return state;
  }
}
