import React from 'react';
import cx from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import logo from 'assets/images/light-logo.svg';
import CredCompareSVGIcon from '../CredCompareSVGIcons';

const useStyles = makeStyles(() => createStyles({
  logo: {
    height: 43,
  },
}));

export const ApplicationLogo = ({
  className,
  useMobileVersion,
}: {className?: string; useMobileVersion?: boolean}) => {
  const styles = useStyles();
  if (useMobileVersion) {
    return <CredCompareSVGIcon className={className} width={28} height={28} name="logoSign" />;
  }

  return <img src={logo} className={cx(styles.logo, className)} alt="logo" />;
};
