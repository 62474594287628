import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { IPaginationData } from '../../types/otherData';
import { ICreateSubmission, ILenderExtra } from '../../types/submissions';
import { getInviteMode } from '../../utils/apiInviteKey';
import { buildSortByPagination } from '../../utils/buildSortByPagination';
import { IApplicant } from '../../types/application';
import { LENDERS_TYPE } from '../../applicationConstants';

const getQuerySearch = (search?: string) => {
  if (!search) return {};

  const searchNumbers = search.replace(/[^0-9]/g, '');

  if (searchNumbers.length > 2 && searchNumbers.length < 11) {
    return {
      applicant_phone_prefix: searchNumbers,
      invite_phone_prefix: searchNumbers,
    };
  }

  return { name_query: search };
};

export const getApplicationsListRequest = (
  data?: IPaginationData & {
    isCharged?: boolean;
  },
) => {
  return apiRequest(
    `submission${toParams({
      ...getQuerySearch(data?.search),
      start: data?.start,
      count: data?.count,
      location: data?.location,
      assignee: data?.assignee,
      state: data?.state,
      sort_by: buildSortByPagination(data),
      is_charged: data?.isCharged,
    })}`,
    {
      method: 'GET',
    },
  );
};

export const createSubmissionRequest = (body: ICreateSubmission, location?: string) => apiRequest(
  `submission${toParams({ invite: getInviteMode(), location })}`,
  {
    method: 'POST',
    body,
  },
);

export type LegalApprovals = Record<string, {
  'iAgree': boolean,
  'terms': string,
  'approvedAt': string,
}>;

export type ReSubmitProps = {
  applicant?: Partial<Omit<IApplicant, 'network' | 'location'>>,
  submissionId: string,
  location?: string;
  legalApprovals: LegalApprovals;
  lenders?: Array<LENDERS_TYPE>
  extra?: ILenderExtra;
};

export const reSubmitSubmissionRequest = ({
  submissionId,
  location,
  applicant,
  legalApprovals,
  lenders,
  extra = {},
}: ReSubmitProps) => apiRequest(
  `submission/${submissionId}${toParams({ invite: getInviteMode(), location })}`,
  {
    method: 'PATCH',
    body: {
      body: applicant,
      legal_approvals: legalApprovals,
      extra,
      lenders,
    },
  },
);

export const getSubmissionRequest = (id: string) => apiRequest(
  `submission/${id}${toParams({ invite: getInviteMode() })}`, {
    method: 'GET',
  },
);

export const goNextApplicationRequest = (submissionId: string, keepCurrent: boolean = false) => apiRequest(
  `submission/${submissionId}/next${toParams({
    invite: getInviteMode(), keep_current: keepCurrent,
  })}`, {
    method: 'POST',
  },
);

export const updateSubmissionRequest = (
  id: string,
  applicationId: string,
) => apiRequest(`submission/${id}/patch${toParams({ invite: getInviteMode() })}`, {
  method: 'PATCH',
  body: {
    currentLenderApplication: applicationId,
  },
});

export const manualApplySubmissionRequest = (
  id: string,
  applicationId: string,
) => apiRequest(`submission/${id}/apply/${applicationId}${toParams({ set_as_current: true })}`, {
  method: 'POST',
});

export const getSubmissionLenderApplicationsRequest = (id: string, currentTierOnly: boolean = false) => apiRequest(
  `submission/${id}/lender_applications${toParams({
    invite: getInviteMode(),
    current_tier_only: currentTierOnly,
  })}`, {
    method: 'GET',
  },
);
