import { StyleRules } from '@material-ui/core/styles';
import { typography } from './typography';
import { breakpoints } from './breakpoints';

const startAdornmentStyles = {
  '&.startAdornment': {
    '& .MuiInputLabel-shrink.MuiInputLabel-outlined': {
      transform: 'translate(50px, -10px)',

      [breakpoints.down('sm')]: {
        transform: 'translate(42px, -10px)',
      },
    },

    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 16,

      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },

      '& .MuiInputBase-input': {
        paddingLeft: 14,
      },

      [breakpoints.down('sm')]: {
        paddingLeft: 14,

        '& .MuiInputBase-input': {
          paddingLeft: 12,
        },
      },
    },
  },
};

const endAdornment = {
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: 16,

    [breakpoints.down('sm')]: {
      paddingRight: 14,
    },
  },
};

const MuiTextFieldOverrides: StyleRules = {
  root: {
    '& .MuiInputBase-root': {
      background: '#F7F9FF',
      height: 56,
      [breakpoints.down('sm')]: {
        height: 48,
      },
      '& input::placeholder': {
        ...typography.body2,
        color: 'rgba(9, 2, 36, 0.7)',
        opacity: 1,
      },
    },
    '& .MuiInputBase-input': {
      ...typography.subtitle2,
    },
    '& .Mui-error': {
      '&.MuiInputBase-root': {
        background: '#FEEFF0',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(208, 48, 50, 0.3)',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 24px 14px 24px',

      [breakpoints.down('sm')]: {
        padding: '12px 24px 12px 18px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      borderColor: 'rgba(9, 2, 36, 0.3)',
      borderRadius: 5,

      '& legend': {
        display: 'none',
      },
    },
    '& MuiTextField-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#090224',
      borderWidth: 2,
    },
    '& .Mui-focused:not(.Mui-error)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#090224',
        borderWidth: 2,
      },
    },
    '& .MuiInputLabel-outlined': {
      ...typography.body2,
      transform: 'translate(24px, 18px)',
      color: 'rgba(9, 2, 36, 0.7)',

      [breakpoints.down('sm')]: {
        transform: 'translate(18px, 14px)',
      },

      '&.MuiInputLabel-shrink': {
        ...typography.caption,
        transform: 'translate(20px, -10px)',
        background: 'white',
        padding: '0 4px',
        borderRadius: 4,
        color: 'rgba(9, 2, 36, 0.7)',

        [breakpoints.down('sm')]: {
          transform: 'translate(16px, -10px)',
        },
      },

      '&.Mui-focused': {
        color: 'rgba(9, 2, 36, 0.7)',
      },
      '&.Mui-error': {
        color: 'rgba(186, 30, 28, 1)',
      },
    },
    '& .MuiFormHelperText-root': {
      ...typography.caption,
      color: 'rgba(9, 2, 36, 0.7)',

      '&.Mui-error': {
        color: 'rgba(186, 30, 28, 1)',
        marginLeft: 24,
      },
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',

      '& .MuiInputLabel-outlined': {
        color: 'rgba(9, 2, 36, 0.3)',
      },

      '& .MuiInputBase-input': {
        color: 'rgba(9, 2, 36, 0.5)',
      },
      '&.MuiInputBase-root': {
        background: 'rgba(9, 2, 36, 0.15)',
      },

      '& .MuiFormHelperText-root': {
        color: 'rgba(9, 2, 36, 0.3)',
      },
    },
    ...startAdornmentStyles,
    ...endAdornment,
  },
};

export default MuiTextFieldOverrides;
