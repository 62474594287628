import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { applicationFlowMode } from 'utils/applicationModeUtils';
import { ROOT } from '../../routes';
import { ApplicationLogo } from '../AppllicationLogo';
import { UserDropdown } from '../../features/UserDropdown';
import { CcBox } from '../CcBox';

const useStyles = makeStyles(() => createStyles({
  header: {
    width: '100%',
  },
  appHeader: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    height: 112,
    zIndex: 10,
  },
}));

const AppHeader = () => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('desktop'));
  const styles = useStyles();
  const auth = useSelector((state) => state.auth);

  return (
    <CcBox
      component="header"
      className={cx(styles.header, isDesktop && styles.appHeader)}
      padding="m l"
    >
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        {
          applicationFlowMode === 'general' ? (
            <Link
              to={ROOT.url({})}
            >
              <ApplicationLogo
                useMobileVersion={!isDesktop}
              />
            </Link>
          ) : (
            <ApplicationLogo
              useMobileVersion={!isDesktop}
            />
          )
        }
        {
          auth.status === 'loaded' && <UserDropdown />
        }
      </Box>
    </CcBox>
  );
};

export default AppHeader;
