import {
  authByInviteRequest,
  createSubmissionRequest,
  getSubmissionRequest,
  goNextApplicationRequest,
  manualApplySubmissionRequest,
  ReSubmitProps,
  reSubmitSubmissionRequest,
  updateSubmissionRequest,
} from 'store/apiRequests';
import { getLenderApplication } from 'store/application';
import {
  LENDER_APPLICATION_STAGES,
  LENDER_APPLICATION_STATUSES,
  LENDERS,
  SUBMISSION_STATUSES,
} from 'applicationConstants';
import get from 'lodash/get';
import {
  createSubmissionAction,
  getSubmissionAction,
  goNextApplicationSubmissionAction,
  manualApplySubmissionAction,
  reSubmitSubmissionAction,
  SUBMISSION_UPDATE,
  updateSubmissionAction,
} from './actionTypes';
import { SUBMISSION_LOADING_PAGE } from '../../routes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { ICreateSubmission, ISubmission } from '../../types/submissions';
import { getInviteMode } from '../../utils/apiInviteKey';
import { setAlterToken } from '../../utils/apiRequest';
import { waitWhileCondition } from '../../utils/requestWithTimeout';
import { ILenderApplication } from '../../types/application';
import { setHfdServicingOffersDetails } from '../lenderSpecific';

type LocationField = {
  location?: string,
};

export const submissionWSUpdate = (submission: ISubmission) => {
  return {
    type: SUBMISSION_UPDATE,
    payload: submission,
  };
};

export const createSubmission = defThunkRequest<ICreateSubmission & LocationField>({
  actionTypes: createSubmissionAction,
  thunkSteps: [
    ({ data: { location, ...body } }) => createSubmissionRequest(body, location),
    async ({
      prevResponse, data, history,
    }) => {
      if (getInviteMode()) {
        const { data: { token } } = await authByInviteRequest({ zipCode: data.applicant.zipCode });
        setAlterToken(token);
      }

      const { data: { id } } = prevResponse;

      history?.push(SUBMISSION_LOADING_PAGE.url({
        submissionId: id,
      }));

      return prevResponse;
    },
  ],
});

export const reSubmitSubmissionThunk = defThunkRequest<ReSubmitProps>({
  actionTypes: reSubmitSubmissionAction,
  thunkSteps: [
    ({
      data,
    }) => reSubmitSubmissionRequest(data),
    async ({
      prevResponse, dispatch, history,
    }) => {
      const { data: { id } } = prevResponse;

      history?.push(SUBMISSION_LOADING_PAGE.url({
        submissionId: id,
      }));

      dispatch(loadSubmission({
        data: {
          submissionId: id,
        },
        history,
      }));

      return prevResponse;
    },
  ],
});

export const csReSubmitSubmissionThunk = defThunkRequest<ReSubmitProps>({
  actionTypes: reSubmitSubmissionAction,
  thunkSteps: [
    ({
      data,
    }) => reSubmitSubmissionRequest(data),
  ],
});

export const getSubmissionSolely = defThunkRequest<{submissionId: string}, ISubmission>({
  actionTypes: getSubmissionAction,
  thunkSteps: [
    ({ data: { submissionId } }) => {
      return getSubmissionRequest(submissionId);
    },
  ],
});

type LoadSubmission = {
  submissionId: string;
  refresh?: boolean;
  suppressWaiting?: boolean;
  suppressProceed?: boolean;
  hfdServicingOfferDetails?: {
    dp: string;
    term: number
  }
};

export const loadSubmission = defThunkRequest<LoadSubmission, ISubmission>({
  actionTypes: getSubmissionAction,
  thunkSteps: [
    ({ data: { submissionId, suppressWaiting }, getState }) => {
      const { configurations: { data: configurationData } } = getState() as RootState;

      if (suppressWaiting) {
        return getSubmissionRequest(submissionId);
      }

      const tiersLength = get(configurationData, 'lenderTiers', []).length;

      return waitWhileCondition({
        request: () => getSubmissionRequest(submissionId),
        condition: (data: any) => {
          return !data.currentLenderApplication && data.status !== SUBMISSION_STATUSES.declined;
        },
        timeout: 30 * (tiersLength + 1),
        every: 3,
      });
    },
    async ({
      prevResponse,
      history,
      dispatch,
      data: {
        refresh,
        submissionId,
        suppressWaiting,
        suppressProceed,
        hfdServicingOfferDetails,
      },
    }) => {
      const {
        data: {
          currentLenderApplication,
        },
      } = prevResponse;
      if (
        (currentLenderApplication?.status === LENDER_APPLICATION_STATUSES.declined
          || currentLenderApplication?.status === LENDER_APPLICATION_STATUSES.error
          || currentLenderApplication?.status === LENDER_APPLICATION_STATUSES.validationError
          || currentLenderApplication?.status === LENDER_APPLICATION_STATUSES.configError)
          && !suppressProceed
      ) {
        dispatch(goNextApplicationSubmissionThunk({ data: { submissionId }, history }));
        return prevResponse;
      }
      if (currentLenderApplication) {
        if (currentLenderApplication.lender === LENDERS.hfd_servicing
          && currentLenderApplication.stage !== LENDER_APPLICATION_STAGES.approved
          && hfdServicingOfferDetails) {
          dispatch(setHfdServicingOffersDetails({
            history,
            data: {
              applicationId: currentLenderApplication.id,
              ...hfdServicingOfferDetails,
            },
          }));
        }
        const getLenderApplicationPromise = () => new Promise(
          (res, rej) => {
            dispatch(getLenderApplication({
              data: {
                id: currentLenderApplication.id,
                suppressWaiting,
                refresh,
              },
              history,
              onSuccess: res,
              onFailure: rej,
            }));
          },
        );
        const res = await getLenderApplicationPromise();
        const { data } = res as {data?: ILenderApplication};
        if (data) {
          return {
            ...prevResponse,
            data: {
              ...prevResponse.data,
              currentLenderApplication: data.application,
            },
          };
        }
        return prevResponse;
      }

      return prevResponse;
    },
  ],
});

export const goNextApplicationSubmissionThunk = defThunkRequest<{
  submissionId: string;
  keepCurrent?: boolean;
}>({
  actionTypes: goNextApplicationSubmissionAction,
  thunkSteps: [
    ({
      data: { submissionId, keepCurrent },
    }) => goNextApplicationRequest(submissionId, keepCurrent),
    ({
      history,
      dispatch,
      data: { submissionId },
    }) => {
      dispatch(loadSubmission({
        data: {
          submissionId,
        },
        history,
      }));
      return null;
    },
  ],
});

export const updateSubmission = defThunkRequest<{
  submissionId: string; lenderApplicationId: string; updateOnly?: boolean;
}>({
  actionTypes: updateSubmissionAction,
  thunkSteps: [
    ({
      data: { submissionId, lenderApplicationId },
    }) => updateSubmissionRequest(submissionId, lenderApplicationId),
    ({
      history,
      dispatch,
      prevResponse,
      data: { submissionId, updateOnly },
    }) => {
      if (!updateOnly) {
        dispatch(loadSubmission({
          data: {
            submissionId,
          },
          history,
        }));
      }
      return prevResponse;
    },
  ],
});
export const manualApplySubmissionThunk = defThunkRequest<{
  submissionId: string; lenderApplicationId: string; updateOnly?: boolean;
}>({
  actionTypes: manualApplySubmissionAction,
  thunkSteps: [
    ({
      data: { submissionId, lenderApplicationId },
    }) => manualApplySubmissionRequest(submissionId, lenderApplicationId),
    ({
      history,
      dispatch,
      prevResponse,
      data: { submissionId, updateOnly },
    }) => (updateOnly
      ? prevResponse
      : dispatch(loadSubmission({
        data: {
          submissionId,
        },
        history,
      }))),
  ],
});
