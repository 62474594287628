import merge from 'lodash/merge';
import { LENDERS } from 'applicationConstants';
import cloneDeep from 'lodash/cloneDeep';
import generalStrings from './generalStrings.json';
import gsStrings from './gsStrings.json';
import allegroStrings from './allegroStrings.json';
import alphaeon2Strings from './alphaeon2Strings.json';
import splititStrings from './splitItStrings.json';
import proceedStrings from './proceedStrings.json';
import affStrings from './affStrings.json';
import uga2Strings from './uga2Strings.json';
import clearGageStrings from './clearGageStrings.json';
import hfdStrings from './hfdStrings.json';
import hfdServicingStrings from './hfdServicingStrings.json';
import hfdSecondStrings from './hfdSecondStrings.json';
import greatamericaStrings from './greatamericaStrings.json';
import varidiStrings from './varidiStrings.json';
import genesisStrings from './genesisStrings.json';
import patientfiStrings from './patientfiStrings.json';
import powerpayStrings from './powerpayStrings.json';
import allyStrings from './allyStrings.json';
import idealStrings from './idealStrings.json';
import ugaPrimeStrings from './ugaPrimeStrings.json';
import fortivaStrings from './fortivaStrings.json';
import coveredCareStrings from './coveredCareStrings.json';

export default {
  general: generalStrings,
  [LENDERS.greensky]: gsStrings,
  [LENDERS.patientfi]: patientfiStrings,
  [LENDERS.allegro]: allegroStrings,
  [LENDERS.alphaeon2]: alphaeon2Strings,
  [LENDERS.splitit]: splititStrings,
  [LENDERS.fortiva]: fortivaStrings,
  [LENDERS.proceed]: proceedStrings,
  [LENDERS.covered_care]: coveredCareStrings,
  [LENDERS.aff]: affStrings,
  [LENDERS.uga2]: uga2Strings,
  [LENDERS.uga_prime]: {
    ...uga2Strings,
    ...ugaPrimeStrings,
  },
  [LENDERS.cleargage]: clearGageStrings,
  [LENDERS.hfd]: hfdStrings,
  [LENDERS.hfd_second]: merge(cloneDeep(hfdStrings), hfdSecondStrings),
  [LENDERS.hfd_servicing]: merge(cloneDeep(hfdStrings), hfdServicingStrings),
  [LENDERS.ideal]: merge(cloneDeep(hfdStrings), idealStrings),
  [LENDERS.greatamerica]: greatamericaStrings,
  [LENDERS.varidi]: varidiStrings,
  [LENDERS.genesis]: genesisStrings,
  [LENDERS.powerpay]: powerpayStrings,
  [LENDERS.ally]: allyStrings,
};
