import { ReactComponent as calendar } from 'assets/icons-svg/calendar.svg';
import { ReactComponent as receipt16 } from 'assets/icons-svg/receiept16.svg';
import { ReactComponent as notFound } from 'assets/icons-svg/notFound.svg';
import { ReactComponent as dollarSign } from 'assets/icons-svg/dollar-sign.svg';
import { ReactComponent as arrowLeft24 } from 'assets/icons-svg/arrowLeft24.svg';
import { ReactComponent as documentSigned24 } from 'assets/icons-svg/documentSigned24.svg';
import { ReactComponent as listIcon } from 'assets/icons-svg/listIcon.svg';
import { ReactComponent as charges24 } from 'assets/icons-svg/charges24.svg';
import { ReactComponent as copy24 } from 'assets/icons-svg/copy24.svg';
import { ReactComponent as error72 } from 'assets/icons-svg/error72.svg';
import { ReactComponent as location24 } from 'assets/icons-svg/location24.svg';
import { ReactComponent as info16 } from 'assets/icons-svg/info16.svg';
import { ReactComponent as map24 } from 'assets/icons-svg/map24.svg';
import { ReactComponent as message24 } from 'assets/icons-svg/message24.svg';
import { ReactComponent as doubleArrow16 } from 'assets/icons-svg/doubleArrow16.svg';
import { ReactComponent as calendar24 } from 'assets/icons-svg/calendar24.svg';
import { ReactComponent as clock24 } from 'assets/icons-svg/clock24.svg';
import { ReactComponent as stamp24 } from 'assets/icons-svg/stamp24.svg';
import { ReactComponent as card24 } from 'assets/icons-svg/card24.svg';
import { ReactComponent as activeFilter16 } from 'assets/icons-svg/activeFilter16.svg';
import { ReactComponent as ssn24 } from 'assets/icons-svg/ssn24.svg';
import { ReactComponent as plus16 } from 'assets/icons-svg/plus16.svg';
import { ReactComponent as info24 } from 'assets/icons-svg/info24.svg';
import { ReactComponent as lockIcon } from 'assets/icons-svg/lockIcon.svg';
import { ReactComponent as blueCalendar96 } from 'assets/icons-svg/blueCalendar96.svg';
import { ReactComponent as bin24 } from 'assets/icons-svg/bin24.svg';
import { ReactComponent as arrowRight16 } from 'assets/icons-svg/arrowRight16.svg';
import { ReactComponent as phone24 } from 'assets/icons-svg/phone24.svg';
import { ReactComponent as info96 } from 'assets/icons-svg/info96.svg';
import { ReactComponent as allUsers24 } from 'assets/icons-svg/allUsers24.svg';
import { ReactComponent as mineUser24 } from 'assets/icons-svg/mineUser24.svg';
import { ReactComponent as signedDocument24 } from 'assets/icons-svg/signedDocument24.svg';
import { ReactComponent as filter } from 'assets/icons-svg/filter.svg';
import { ReactComponent as callSupport96 } from 'assets/icons-svg/callSupport96.svg';
import { ReactComponent as homeIcon } from 'assets/icons-svg/homeIcon.svg';
import { ReactComponent as sortingAsc } from 'assets/icons-svg/sorting-asc.svg';
import { ReactComponent as sortingDesc } from 'assets/icons-svg/sorting-desc.svg';
import { ReactComponent as check } from 'assets/icons-svg/check.svg';
import { ReactComponent as download } from 'assets/icons-svg/download.svg';
import { ReactComponent as supportMessage } from 'assets/icons-svg/supportMessage.svg';
import { ReactComponent as arrowLeft } from 'assets/icons-svg/arrow-left.svg';
import { ReactComponent as edit24 } from 'assets/icons-svg/edit24.svg';
import { ReactComponent as arrowRight } from 'assets/icons-svg/arrow-right.svg';
import { ReactComponent as arrowDown } from 'assets/icons-svg/arrow-down.svg';
import { ReactComponent as arrowDown24 } from 'assets/icons-svg/arrowDown24.svg';
import { ReactComponent as arrowUp24 } from 'assets/icons-svg/arrowUp24.svg';
import { ReactComponent as dollar24 } from 'assets/icons-svg/dollar24.svg';
import { ReactComponent as cross } from 'assets/icons-svg/cross.svg';
import { ReactComponent as refund } from 'assets/icons-svg/refund.svg';
import { ReactComponent as cashIcon } from 'assets/icons-svg/cash-icon.svg';
import { ReactComponent as interestIcon } from 'assets/icons-svg/interest.svg';
import { ReactComponent as loanAmountIcon } from 'assets/icons-svg/loan-amount.svg';
import { ReactComponent as searchIcon } from 'assets/icons-svg/search.svg';
import { ReactComponent as search24 } from 'assets/icons-svg/search24.svg';
import { ReactComponent as boxIcon } from 'assets/icons-svg/box.svg';
import { ReactComponent as regionsIcon } from 'assets/icons-svg/regionsIcon.svg';
import { ReactComponent as signedStatusIcon } from 'assets/icons-svg/adminStatuses/signedStatus.svg';
import { ReactComponent as reviewIcon } from 'assets/icons-svg/reviewIcon.svg';
import { ReactComponent as clearFilters16 } from 'assets/icons-svg/clearFilters16.svg';
import { ReactComponent as review24 } from 'assets/icons-svg/review24.svg';
import { ReactComponent as profileIcon } from 'assets/icons-svg/profileIcon.svg';
import { ReactComponent as manageUsers } from 'assets/icons-svg/manageUsers.svg';
import { ReactComponent as logout } from 'assets/icons-svg/logout.svg';
import { ReactComponent as deleteIcon } from 'assets/icons-svg/delete.svg';
import { ReactComponent as attentionIcon } from 'assets/icons-svg/attentionIcon.svg';
import { ReactComponent as attention24 } from 'assets/icons-svg/attention24.svg';
import { ReactComponent as manageLenders } from 'assets/icons-svg/manageLenders.svg';
import { ReactComponent as building24 } from 'assets/icons-svg/building24.svg';
import { ReactComponent as applications } from 'assets/icons-svg/applications.svg';
import { ReactComponent as plainAttention } from 'assets/icons-svg/plainAttention.svg';
import { ReactComponent as creditCardInstallement } from 'assets/icons-svg/creditCardInstallement.svg';
import { ReactComponent as warningIcon } from 'assets/icons-svg/warningIcon.svg';
import { ReactComponent as dollarIcon } from 'assets/icons-svg/dollar.svg';
import { ReactComponent as freePlanIcon } from 'assets/images/icons/free-plan.svg';
import { ReactComponent as repeatIcon } from 'assets/images/icons/repeat.svg';
import { ReactComponent as calendarIcon } from 'assets/images/icons/calendar.svg';
import { ReactComponent as checksheetIcon } from 'assets/images/icons/checksheet.svg';
import { ReactComponent as installmentsIcon } from 'assets/images/icons/installments.svg';
import { ReactComponent as invoiceIcon } from 'assets/images/icons/invoice.svg';
import { ReactComponent as editDocument } from 'assets/icons-svg/editDocument.svg';
import { ReactComponent as editPen } from 'assets/icons-svg/editPen.svg';
import { ReactComponent as smallTrash } from 'assets/icons-svg/smallTrash.svg';
import { ReactComponent as creditCard } from 'assets/icons-svg/creditCard.svg';
import { ReactComponent as reloadIcon } from 'assets/icons-svg/reloadIcon.svg';
import { ReactComponent as locationsIcon } from 'assets/icons-svg/locationsIcon.svg';
import { ReactComponent as hourGlassBig } from 'assets/icons-svg/hourGlassBig.svg';
import { ReactComponent as termsLoadingIcon } from 'assets/icons-svg/termsLoadingIcon.svg';
import { ReactComponent as paycheckUpdatedDesign } from 'assets/icons-svg/paycheckUpdatedDesign.svg';
import { ReactComponent as infoSymbol } from 'assets/icons-svg/infoSymbol.svg';
import { ReactComponent as outlinedInfo } from 'assets/icons-svg/outlinedInfo.svg';
import { ReactComponent as logoSign } from 'assets/images/logo-sign.svg';
import { ReactComponent as linkIcon } from 'assets/icons-svg/link.svg';
import { ReactComponent as roundedQuestionIcon } from 'assets/icons-svg/roundedQuestionIcon.svg';
import { ReactComponent as percentIcon } from 'assets/icons-svg/percentIcon.svg';
import { ReactComponent as downpayment } from 'assets/icons-svg/downpayment.svg';
import { ReactComponent as hfdCard } from 'assets/icons-svg/hfdCard.svg';
import { ReactComponent as hfdBankAccount } from 'assets/icons-svg/hfdBankAccount.svg';
import { ReactComponent as outlinedInfo24 } from 'assets/icons-svg/outlinedInfo24.svg';
import { ReactComponent as warningQuestionOutlined } from 'assets/icons-svg/warningQuestionOutlined.svg';
import { ReactComponent as locationMark } from 'assets/icons-svg/locationMark.svg';
import { ReactComponent as hierarchyIcon } from 'assets/icons-svg/hierarchyIcon.svg';
import { ReactComponent as approvedStatus } from 'assets/icons-svg/adminStatuses/approvedStatus.svg';
import { ReactComponent as expiredStatus } from 'assets/icons-svg/adminStatuses/expiredStatus.svg';
import { ReactComponent as waitingStatus } from 'assets/icons-svg/adminStatuses/waitingStatus.svg';
import { ReactComponent as timeHand } from 'assets/icons-svg/timeHand.svg';
import { ReactComponent as loginInPortal36 } from 'assets/icons-svg/loginInPortal36.svg';
import { ReactComponent as congratsCalendar } from 'assets/icons-svg/congratsCalendar.svg';
import { ReactComponent as cardIcon } from 'assets/icons-svg/cardIcon.svg';
import { ReactComponent as sendToPhone } from 'assets/icons-svg/sendToPhone.svg';
import { ReactComponent as sendToPhone2 } from 'assets/icons-svg/sendToPhone2.svg';
import { ReactComponent as refresh } from 'assets/icons-svg/refresh.svg';
import { ReactComponent as globusIcon } from 'assets/icons-svg/globusIcon.svg';
import { ReactComponent as approved96 } from 'assets/icons-svg/approved96.svg';
import { ReactComponent as error96 } from 'assets/icons-svg/error96.svg';
import { ReactComponent as iMessage24 } from 'assets/icons-svg/iMessage24.svg';
import { ReactComponent as earth } from 'assets/icons-svg/earth.svg';
import { ReactComponent as earth24 } from 'assets/icons-svg/earth24.svg';
import { ReactComponent as bigblurryAttention } from 'assets/icons-svg/bigblurryAttention.svg';
import { ReactComponent as home24 } from 'assets/icons-svg/home24.svg';
import { ReactComponent as doubleArrowRefresh } from 'assets/icons-svg/doubleArrowRefresh.svg';
import { ReactComponent as doubleRefresh24 } from 'assets/icons-svg/doubleRefresh24.svg';
import { ReactComponent as mail24 } from 'assets/icons-svg/mail24.svg';
import { ReactComponent as whatsapp24 } from 'assets/icons-svg/whatsapp24.svg';
import { ReactComponent as binoculars24 } from 'assets/icons-svg/binoculars24.svg';
import { ReactComponent as faq72 } from 'assets/icons-svg/faq72.svg';
import { ReactComponent as contact24 } from 'assets/icons-svg/contact24.svg';
import { ReactComponent as back16 } from 'assets/icons-svg/back16.svg';
import { ReactComponent as arrowRight24 } from 'assets/icons-svg/arrowRight24.svg';
import { ReactComponent as redCircleCross24 } from 'assets/icons-svg/redCircleCross24.svg';
import { ReactComponent as cross24 } from 'assets/icons-svg/cross24.svg';
import { ReactComponent as plusCircle16 } from 'assets/icons-svg/plusCircle16.svg';
import { ReactComponent as plusCircle24 } from 'assets/icons-svg/plusCircle24.svg';
import { ReactComponent as avatarActive32 } from 'assets/icons-svg/avatarActive32.svg';
import { ReactComponent as avatar32 } from 'assets/icons-svg/avatar32.svg';
import { ReactComponent as animatedLogo } from 'assets/icons-svg/animatedLogo.svg';

const ICONS = {
  delete: deleteIcon,
  dollar24,
  avatar32,
  animatedLogo,
  avatarActive32,
  back16,
  location24,
  copy24,
  doubleArrow16,
  plusCircle16,
  stamp24,
  cross24,
  plusCircle24,
  arrowRight24,
  redCircleCross24,
  clock24,
  ssn24,
  phone24,
  info96,
  activeFilter16,
  earth24,
  home24,
  arrowLeft24,
  documentSigned24,
  contact24,
  search24,
  message24,
  whatsapp24,
  building24,
  binoculars24,
  faq72,
  globusIcon,
  attention24,
  approved96,
  error96,
  earth,
  arrowRight16,
  iMessage24,
  listIcon,
  charges24,
  mail24,
  card24,
  freePlanIcon,
  creditCardInstallement,
  bigblurryAttention,
  doubleRefresh24,
  doubleArrowRefresh,
  warningQuestionOutlined,
  calendar24,
  locationMark,
  download,
  sendToPhone,
  sendToPhone2,
  refresh,
  lockIcon,
  hfdBankAccount,
  hfdCard,
  downpayment,
  roundedQuestionIcon,
  approvedStatus,
  hierarchyIcon,
  waitingStatus,
  homeIcon,
  expiredStatus,
  percentIcon,
  linkIcon,
  error72,
  logoSign,
  regionsIcon,
  outlinedInfo,
  repeatIcon,
  paycheckUpdatedDesign,
  infoSymbol,
  edit24,
  map24,
  bin24,
  review24,
  termsLoadingIcon,
  locationsIcon,
  info24,
  info16,
  supportMessage,
  editPen,
  reloadIcon,
  arrowDown24,
  arrowUp24,
  hourGlassBig,
  checksheetIcon,
  creditCard,
  smallTrash,
  editDocument,
  notFound,
  invoiceIcon,
  installmentsIcon,
  calendarIcon,
  receipt16,
  attentionIcon,
  profileIcon,
  warningIcon,
  dollarIcon,
  plainAttention,
  manageUsers,
  signedDocument24,
  callSupport96,
  applications,
  manageLenders,
  logout,
  reviewIcon,
  clearFilters16,
  signedStatusIcon,
  blueCalendar96,
  boxIcon,
  searchIcon,
  cardIcon,
  cashIcon,
  interestIcon,
  loanAmountIcon,
  calendar,
  dollarSign,
  mineUser24,
  allUsers24,
  plus16,
  outlinedInfo24,
  sortingAsc,
  sortingDesc,
  filter,
  check,
  arrowLeft,
  arrowRight,
  arrowDown,
  cross,
  refund,
  timeHand,
  loginInPortal36,
  congratsCalendar,
} as const;

export default ICONS;
