// eslint-disable-next-line no-restricted-imports
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    largeDesktop: true;
    desktop: true;
  }
}

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    mobile: 0,
    tablet: 600,
    desktop: 960,
    largeDesktop: 1280,
  },
});
