import { ExtractRouteParams, generatePath, matchPath } from 'react-router';
import { RouteType } from '../types/route';
import { toParams } from './params';

export function defRoute<S extends string>(pattern: S): RouteType<S> {
  return {
    pattern,
    url: (
      params?: ExtractRouteParams<S>,
      search?: { [key: string]: number | string | boolean | undefined | Array<string> },
    ) => `${generatePath(pattern, params)}${toParams(search)}`,
    isMatch: (path, exact = true) => !!matchPath(path, {
      path: pattern,
      exact,
    })?.isExact,
  };
}
