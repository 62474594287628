import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { IInviteBody } from '../../types/invite';

export const inviteSmsRequest = (
  body: IInviteBody,
  location?: string,
) => apiRequest(
  `invite${toParams({ location })}`,
  {
    method: 'POST',
    body,
  },
);
export const getInviteDetailsRequest = (
  inviteKey: string,
) => apiRequest(
  `invite/${inviteKey}`,
  {
    method: 'GET',
  },
);
