import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { AlphaeonAccountLookup, GsAccountLookup } from '../../types/accountLookup';
import { LENDERS_TYPE } from '../../applicationConstants';
import { getInviteMode } from '../../utils/apiInviteKey';

export type SearchAccountType = (GsAccountLookup | AlphaeonAccountLookup) & {
  lender: LENDERS_TYPE;
  location?: string;
};

export const searchForExistingAccountRequest = (
  { location, ...body }: SearchAccountType,
) => apiRequest(
  `lender_account/lookup${toParams({ location, invite: getInviteMode() })}`,
  {
    method: 'POST',
    body,
  },
);

export type CreateSubmissionByAccountLookup = {
  body: {
    lender_account: string,
    phone: string,
    email?: string,
    legal_approval: {
      iAgree: boolean,
      terms: string,
      approvedAt: string,
    },
  },
  location: string,
};

export const createSubmissionBYExistingAccountRequest = ({
  body,
  location,
}: CreateSubmissionByAccountLookup) => apiRequest(
  `submission/from_account${toParams({ location, invite: getInviteMode() })}`,
  {
    method: 'POST',
    body,
  },
);

export const getListOfLocationsLenders = () => apiRequest(
  'lender_account/lookup/locations',
  {
    method: 'GET',
  },
);
