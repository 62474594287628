import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../applicationConstants';
import {
  FAQ_URL,
  FORCE_CHANGE_LENDER,
  IS_CHANGE_LENDER_ENABLED,
  IS_FINAL_PRICE_ENABLED,
  RELEASE_NOTES_URL,
} from '../applicationConstants/envVariables';
import { includesInUnion } from '../utils/includesInUnion';

type Props = {
  isSplitItSupported?: boolean;
  isHfdServicingSupported?: boolean;
  maxPayments?: number;
  isFinalPriceEnabled?: boolean;
  enableSelectLenderByDefault?: boolean;
  isLaserAway?: boolean;
  isIdeal?: boolean;
  useEmailInvite?: boolean;
  guestOnlyFlow?: boolean;
  transactionRefundsAllowed?: boolean;
  preferLowestMonthlyOffer?: boolean;
  networkType?: string;
  faqUrl?: string;
  releaseNotesUrl?: string;
};

export const ApplicantConfigurationContext = React.createContext<Props>({
});

export const useCcConfigurationContext = () => {
  return useContext(ApplicantConfigurationContext);
};

const useApplicationConfigurationContextValues = (): Props => {
  const authInfo = useSelector((state) => state.auth);
  const lenderConfigurations = useSelector((state) => state.configurations);

  const {
    enableFinalPrice,
    enableSelectLenderByDefault,
    whitelabel,
    faqUrl,
    releaseNotesUrl,
    guestOnlyFlow,
    managersOnlyRefunds,
    preferLowestMonthlyOffer,
  } = useMemo(() => {
    return (authInfo?.network?.flags || {});
  }, [authInfo?.network?.flags]);

  const transactionRefundsAllowed = useMemo(() => {
    return !managersOnlyRefunds || includesInUnion([
      USER_ROLES.locationAdmin,
      USER_ROLES.networkAdmin,
      USER_ROLES.regionalManager,
      USER_ROLES.support,
    ], authInfo?.userInfo?.role);
  }, [authInfo?.userInfo?.role, managersOnlyRefunds]);

  const faqToUse = useMemo(() => {
    if (faqUrl === undefined || faqUrl === null) {
      return FAQ_URL;
    }
    return faqUrl;
  }, [faqUrl]);

  const releaseNotesToUse = useMemo(() => {
    if (releaseNotesUrl === undefined || releaseNotesUrl === null) {
      return RELEASE_NOTES_URL;
    }
    return releaseNotesUrl;
  }, [releaseNotesUrl]);

  return useMemo(() => {
    return {
      isSplitItSupported: lenderConfigurations.data?.splitit?.isSupported,
      isHfdServicingSupported: lenderConfigurations.data?.hfdServicing,
      maxPayments: lenderConfigurations.data?.splitit?.maxPayments,
      isFinalPriceEnabled: enableFinalPrice === null || enableFinalPrice === undefined
        ? IS_FINAL_PRICE_ENABLED
        : enableFinalPrice,
      enableSelectLenderByDefault: FORCE_CHANGE_LENDER
        && (enableSelectLenderByDefault === null || enableSelectLenderByDefault === undefined
          ? IS_CHANGE_LENDER_ENABLED
          : enableSelectLenderByDefault),
      isLaserAway: whitelabel === 'laser_away',
      isIdeal: whitelabel === 'ideal_image',
      useEmailInvite: false,
      networkType: whitelabel,
      faqUrl: faqToUse,
      releaseNotesUrl: releaseNotesToUse,
      guestOnlyFlow: !!guestOnlyFlow,
      transactionRefundsAllowed,
      preferLowestMonthlyOffer,
    };
  }, [
    lenderConfigurations.data?.splitit?.isSupported,
    lenderConfigurations.data?.splitit?.maxPayments,
    lenderConfigurations.data?.hfdServicing,
    enableFinalPrice,
    enableSelectLenderByDefault,
    whitelabel,
    faqToUse,
    releaseNotesToUse,
    guestOnlyFlow,
    transactionRefundsAllowed,
    preferLowestMonthlyOffer,
  ]);
};

export const CcApplicationContextProvider = ({ children }: {children: React.ReactNode}) => {
  const providerContent = useApplicationConfigurationContextValues();

  return (
    <ApplicantConfigurationContext.Provider value={providerContent}>
      {children}
    </ApplicantConfigurationContext.Provider>
  );
};
