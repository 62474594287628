import {
  getLendersStatusAction,
  recoverLenderAction,
  setLenderStatusAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { ILenderStatus } from '../../types/otherData';
import {
  getLendersStatusRequest, RecoverBody, recoverLenderFromCrashRequest, setLenderStatusRequest,
} from '../apiRequests';

export const getLendersStatusThunk = defThunkRequest<undefined, Array<ILenderStatus>>({
  actionTypes: getLendersStatusAction,
  thunkSteps: [
    () => getLendersStatusRequest(),
  ],
});

export const setLenderStatusThunk = defThunkRequest<ILenderStatus, boolean>({
  actionTypes: setLenderStatusAction,
  thunkSteps: [
    ({
      data,
    }) => setLenderStatusRequest(data),
  ],
});

export const recoverLenderThunk = defThunkRequest<RecoverBody>({
  actionTypes: recoverLenderAction,
  thunkSteps: [
    ({
      data,
    }) => recoverLenderFromCrashRequest(data),
  ],
});
