import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

type AllowedSpacings = 'xs' | 's' | 'm' | 'l' | 'none';

type SpacingValues = {
  top?: AllowedSpacings;
  right?: AllowedSpacings;
  bottom?: AllowedSpacings;
  left?: AllowedSpacings;
};

type Spacing = AllowedSpacings
  | `${AllowedSpacings} ${AllowedSpacings}`
  | `${AllowedSpacings} ${AllowedSpacings} ${AllowedSpacings}`
  | `${AllowedSpacings} ${AllowedSpacings} ${AllowedSpacings} ${AllowedSpacings}`;

export type SpacingType = Spacing | SpacingValues;

type Props<C extends React.ElementType<any>> = {
  children?: React.ReactNode;
  className?: string;
  padding?: SpacingType;
  margin?: SpacingType;
  component?: C;
} & React.ComponentProps<C>;

export const DesktopSpacingValues = {
  none: '0px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '36px',
} as const;

export const MobileSpacingValues = {
  none: '0px',
  xs: '8px',
  s: '12px',
  m: '16px',
  l: '24px',
} as const;

export const TabletSpacingValues = {
  none: '0px',
  xs: '8px',
  s: '14px',
  m: '24px',
  l: '32px',
} as const;

const getSpacingBase = (resolution: 'mobile' | 'desktop' | 'tablet') => {
  if (resolution === 'desktop') {
    return DesktopSpacingValues;
  }
  if (resolution === 'tablet') {
    return TabletSpacingValues;
  }
  return MobileSpacingValues;
};

const buildStringSpacing = (resolution: 'mobile' | 'desktop' | 'tablet', spacing?: Spacing) => {
  if (!spacing) {
    return undefined;
  }
  const spacingBase = getSpacingBase(resolution);
  const splited = spacing.split(' ') as Array<AllowedSpacings>;
  if (splited.length === 1) {
    return spacingBase[splited[0]];
  }
  if (splited.length === 2) {
    return `${spacingBase[splited[0]]} ${spacingBase[splited[1]]}`;
  }
  if (splited.length === 3) {
    return `${spacingBase[splited[0]]} ${spacingBase[splited[1]]} ${spacingBase[splited[2]]}`;
  }
  if (splited.length === 4) {
    // eslint-disable-next-line max-len
    return `${spacingBase[splited[0]]} ${spacingBase[splited[1]]} ${spacingBase[splited[2]]} ${spacingBase[splited[3]]}`;
  }

  return undefined;
};

type StyleProps = {
  padding?: Spacing;
  margin?: Spacing;
  paddingTop?: AllowedSpacings,
  paddingRight?: AllowedSpacings,
  paddingBottom?: AllowedSpacings,
  paddingLeft?: AllowedSpacings,
  marginTop?: AllowedSpacings,
  marginRight?: AllowedSpacings,
  marginBottom?: AllowedSpacings,
  marginLeft?: AllowedSpacings,
};

const useStyles = makeStyles((theme) => ({
  spacing: {
    padding: ({ padding }: StyleProps) => buildStringSpacing('mobile', padding),
    margin: ({ margin }: StyleProps) => buildStringSpacing('mobile', margin),
    paddingTop: ({ paddingTop }: StyleProps) => buildStringSpacing('mobile', paddingTop),
    paddingRight: ({ paddingRight }: StyleProps) => buildStringSpacing('mobile', paddingRight),
    paddingBottom: ({ paddingBottom }: StyleProps) => buildStringSpacing('mobile', paddingBottom),
    paddingLeft: ({ paddingLeft }: StyleProps) => buildStringSpacing('mobile', paddingLeft),
    marginTop: ({ marginTop }: StyleProps) => buildStringSpacing('mobile', marginTop),
    marginRight: ({ marginRight }: StyleProps) => buildStringSpacing('mobile', marginRight),
    marginBottom: ({ marginBottom }: StyleProps) => buildStringSpacing('mobile', marginBottom),
    marginLeft: ({ marginLeft }: StyleProps) => buildStringSpacing('mobile', marginLeft),
    [theme.breakpoints.up('tablet')]: {
      padding: ({ padding }: StyleProps) => buildStringSpacing('tablet', padding),
      margin: ({ margin }: StyleProps) => buildStringSpacing('tablet', margin),
      paddingTop: ({ paddingTop }: StyleProps) => buildStringSpacing('tablet', paddingTop),
      paddingRight: ({ paddingRight }: StyleProps) => buildStringSpacing('tablet', paddingRight),
      paddingBottom: ({ paddingBottom }: StyleProps) => buildStringSpacing('tablet', paddingBottom),
      paddingLeft: ({ paddingLeft }: StyleProps) => buildStringSpacing('tablet', paddingLeft),
      marginTop: ({ marginTop }: StyleProps) => buildStringSpacing('tablet', marginTop),
      marginRight: ({ marginRight }: StyleProps) => buildStringSpacing('tablet', marginRight),
      marginBottom: ({ marginBottom }: StyleProps) => buildStringSpacing('tablet', marginBottom),
      marginLeft: ({ marginLeft }: StyleProps) => buildStringSpacing('tablet', marginLeft),
    },
    [theme.breakpoints.up('desktop')]: {
      padding: ({ padding }: StyleProps) => buildStringSpacing('desktop', padding),
      margin: ({ margin }: StyleProps) => buildStringSpacing('desktop', margin),
      paddingTop: ({ paddingTop }: StyleProps) => buildStringSpacing('desktop', paddingTop),
      paddingRight: ({ paddingRight }: StyleProps) => buildStringSpacing('desktop', paddingRight),
      paddingBottom: ({ paddingBottom }: StyleProps) => buildStringSpacing('desktop', paddingBottom),
      paddingLeft: ({ paddingLeft }: StyleProps) => buildStringSpacing('desktop', paddingLeft),
      marginTop: ({ marginTop }: StyleProps) => buildStringSpacing('desktop', marginTop),
      marginRight: ({ marginRight }: StyleProps) => buildStringSpacing('desktop', marginRight),
      marginBottom: ({ marginBottom }: StyleProps) => buildStringSpacing('desktop', marginBottom),
      marginLeft: ({ marginLeft }: StyleProps) => buildStringSpacing('desktop', marginLeft),
    },
  },
}));

export const CcBox = <C extends React.ElementType<any>>({
  children,
  className,
  padding,
  margin,
  component: Component = 'div',
  ...props
}: Props<C>) => {
  const styleProps = useMemo(() => {
    const res: StyleProps = {
      padding: undefined,
      margin: undefined,
      paddingTop: undefined,
      paddingRight: undefined,
      paddingBottom: undefined,
      paddingLeft: undefined,
      marginTop: undefined,
      marginRight: undefined,
      marginBottom: undefined,
      marginLeft: undefined,
    };
    if (typeof padding === 'string') {
      res.padding = padding as Spacing;
    }
    if (typeof margin === 'string') {
      res.margin = margin as Spacing;
    }
    if (typeof padding === 'object') {
      res.paddingTop = padding.top;
      res.paddingRight = padding.right;
      res.paddingBottom = padding.bottom;
      res.paddingLeft = padding.left;
    }
    if (typeof margin === 'object') {
      res.marginTop = margin.top;
      res.marginRight = margin.right;
      res.marginBottom = margin.bottom;
      res.marginLeft = margin.left;
    }
    return res;
  }, [padding, margin]);
  const styles = useStyles(styleProps);
  return (
    <Component
      className={cx(styles.spacing, className)}
      {...props}
    >
      {
        children
      }
    </Component>
  );
};
