/* eslint-disable camelcase */
import { apiRequest } from '../../utils/apiRequest';
import { getApiMode } from '../../utils/apiModeUtils';
import { toParams } from '../../utils/params';
import { ILenderApplicationApprove } from '../../types/application';
import { getInviteMode } from '../../utils/apiInviteKey';
import { ILenderExtra } from '../../types/submissions';

export const getLenderApplicationRequest = (id: string, refresh?: boolean) => apiRequest(
  `lender_application/${id}${toParams({ invite: getInviteMode(), refresh })}`, {
    method: 'get',
  },
);

export type GetLenderOffersProps = {
  id: string;
  amount?: number;
  dpAmount?: number;
  transactions?: Array<string>;
  quote?: string;
};

export const getLenderOffersRequest = ({
  id,
  amount,
  dpAmount,
  transactions,
  quote,
}: GetLenderOffersProps) => apiRequest(
  `lender_application/${id}/offers${toParams({
    loan_amount: amount,
    invite: getInviteMode(),
    dp_amount: dpAmount,
    route_in: transactions,
    quote,
  })}`, {
    method: 'get',
  },
);

export const getLenderOfferRequest = (id: string) => apiRequest(
  `offer/${id}${toParams({ invite: getInviteMode() })}`, {
    method: 'get',
  },
);

export const approveLenderApplicationRequest = ({ applicationId, values }: ILenderApplicationApprove) => apiRequest(
  `lender_application/${applicationId}/approve${toParams({
    invite: getInviteMode(),
    fake: getApiMode(),
  })}`,
  {
    method: 'POST',
    body: values,
  },
);

export const getLenderAccountRequest = (id: any) => apiRequest(
  `lender_application/${id}/lender_account${toParams({ invite: getInviteMode() })}`, {
    method: 'GET',
  },
);

export const reApplyApplicationRequest = (id: string, body: ILenderExtra) => apiRequest(
  `lender_application/${id}/apply${toParams({ invite: getInviteMode() })}`, {
    method: 'POST',
    body,
  },
);
export const getLenderApplicationLogsRequest = (body: {applicationId: string; reason: string}) => apiRequest(
  `lender_application/${body.applicationId}/logs${toParams({ reason: body.reason })}`, {
    method: 'GET',
  },
);

export const getApplicantRequest = (applicantId: string) => apiRequest(
  `applicant/${applicantId}`, {
    method: 'GET',
  },
);

export const deleteApplicantRequest = (applicantId: string) => apiRequest(
  `applicant/${applicantId}`, {
    method: 'DELETE',
  },
);

export const patchApplicantRequest = ({
  applicantId,
  email,
  phone,
}: {
  applicantId: string;
  email?: string;
  phone?: string;
}) => apiRequest(
  `applicant/${applicantId}`, {
    method: 'PATCH',
    body: {
      email,
      phone,
    },
  },
);

export const resetLenderApplication = (
  applicantId: string,
  extra: {
    status: string, stage: string
  },
) => apiRequest(
  `lender_application/${applicantId}/reset${toParams(extra)}`, {
    method: 'POST',
  },
);

export const requestIncreasedAmountRequest = (lenderApplication: string, amount: number) => apiRequest(
  `lender_application/${lenderApplication}/request_cli`, {
    method: 'POST',
    body: {
      requested_cli_amount: amount,
    },
  },
);
