import {
  deleteLenderConfigurationApiRequest,
  getConfigurationApiRequest,
  getInviteConfigurationRequest,
  getLenderConfigurationApiRequest,
  IUpdateConfigPayload,
  setGreatAmericaProgramApiRequest,
  setUgaProductApiRequest,
  UgaProductRequestBody,
  updateConfigurationApiRequest,
  updateLenderConfigurationApiRequest,
} from 'store/apiRequests';
import {
  CLEAR_CONFIGURATIONS,
  configurationsActions,
  deleteLenderConfigurationActions,
  getInviteConfigurationsActions,
  getLenderConfigurationActions,
  setGreatAmericaProgramActions,
  setPublicLinkConfigurationActions,
  setUgaProductActions,
  updateConfigurationsActions,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { LenderConfiguration, SplititLenderConfig } from '../../types/lenderConfiguration';

export const getConfigurationsThunk = defThunkRequest<{location?: string}>({
  actionTypes: configurationsActions,
  thunkSteps: [
    ({ data }) => getConfigurationApiRequest(data),
  ],
});
export const getInviteConfigurationsThunk = defThunkRequest<{invite: string}>({
  actionTypes: getInviteConfigurationsActions,
  thunkSteps: [
    ({ data: { invite } }: any) => getInviteConfigurationRequest(invite),
  ],
});

type UpdateConfig = {
  locationId?: string
  config: IUpdateConfigPayload;
  lenderConfig?: LenderConfiguration;
  splititConfig?: SplititLenderConfig | null;
  lender?: string;
};

export const updateConfigurationThunk = defThunkRequest<UpdateConfig>({
  actionTypes: updateConfigurationsActions,
  thunkSteps: [
    async ({ data }) => {
      if (!data) {
        throw new Error("Lender configurations couldn't be empty");
      }
      const {
        locationId, config, lenderConfig, splititConfig, lender,
      } = data;
      if (lenderConfig && lender) {
        await updateLenderConfigurationApiRequest({
          lenderConfig,
          lender,
          locationId,
        });
      }
      if ((splititConfig || splititConfig === null) && lender) {
        await updateLenderConfigurationApiRequest({
          lenderConfig: splititConfig,
          lender,
          locationId,
        });
      }
      return updateConfigurationApiRequest(config, locationId);
    },
  ],
});

export const setUgaProductThunk = defThunkRequest<{ locationId?: string } & UgaProductRequestBody>({
  actionTypes: setUgaProductActions,
  thunkSteps: [
    async ({ data }) => {
      const {
        locationId, ...body
      } = data!;

      return setUgaProductApiRequest(body, locationId);
    },
  ],
});

export const setProceedProgramThunk = defThunkRequest<{ locationId?: string; program: string }>({
  actionTypes: setGreatAmericaProgramActions,
  thunkSteps: [
    async ({ data }) => {
      const {
        locationId, ...body
      } = data;

      return setGreatAmericaProgramApiRequest(body, locationId);
    },
  ],
});

type SetPublicLinkConfig = {
  locationId?: string
  enablePublicLink?: boolean;
  id: string
};

export const setPublicLinkConfigurationThunk = defThunkRequest<SetPublicLinkConfig>({
  actionTypes: setPublicLinkConfigurationActions,
  thunkSteps: [
    async ({ data }) => {
      if (!data) {
        throw new Error("Payload can't be empty");
      }
      const {
        locationId, enablePublicLink, id,
      } = data;

      return updateConfigurationApiRequest({ enablePublicLink, id }, locationId);
    },
  ],
});

type GetConfiguration = {
  locationId?: string;
  lender: string;
};

export const getLenderConfigurationThunk = defThunkRequest<GetConfiguration>({
  actionTypes: getLenderConfigurationActions,
  thunkSteps: [
    async ({ data }) => {
      if (!data) {
        throw new Error("Can't load configuration for empty payload");
      }
      const {
        locationId, lender,
      } = data;

      return getLenderConfigurationApiRequest({ locationId, lender });
    },
  ],
});

export const deleteLenderConfigurationThunk = defThunkRequest<GetConfiguration>({
  actionTypes: deleteLenderConfigurationActions,
  thunkSteps: [
    async ({ data }) => {
      if (!data) {
        throw new Error("Can't delete configuration for empty payload");
      }
      const {
        locationId, lender,
      } = data;

      return deleteLenderConfigurationApiRequest({ locationId, lender });
    },
  ],
});

export const clearLenderConfigurations = () => {
  return {
    type: CLEAR_CONFIGURATIONS,
  };
};
