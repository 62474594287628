import React, { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CcBox, DesktopSpacingValues, TabletSpacingValues } from '../../CcBox';
import { useApplicationNavigationContext } from '../ApplicationNavigationContext';
import { LadyStyle } from '../types';

type Props = {
  step?: number;
  goBack?: string | boolean;
  useMobileGoBack?: boolean;
  children: React.ReactNode;
  ladyStyle?: LadyStyle;
};

const useStyles = makeStyles((theme: any) => ({
  page: {
    maxWidth: 1020,
    minWidth: 350,
    width: '100%',

    [theme.breakpoints.up('largeDesktop')]: {
      width: '80%',
    },

    [theme.breakpoints.up('desktop')]: {
      width: `calc(100% - (2 * ${DesktopSpacingValues.l}))`,
    },

    [theme.breakpoints.up('tablet')]: {
      width: `calc(100% - (2 * ${TabletSpacingValues.m}))`,
    },
  },
}));

export const ApplicationPage = ({
  step,
  goBack,
  ladyStyle,
  children,
  useMobileGoBack,
}: Props) => {
  const tabletUp = useMediaQuery((theme: any) => theme.breakpoints.up('tablet'));
  const styles = useStyles();
  const { setConfig } = useApplicationNavigationContext();

  useEffect(() => {
    setConfig({
      currentStep: step,
      goBack,
      ladyStyle,
      useMobileGoBack,
    });

    return () => {
      setConfig({
        currentStep: undefined,
        goBack: undefined,
        ladyStyle: undefined,
        useMobileGoBack: undefined,
      });
    };
  }, [goBack, ladyStyle, setConfig, step]);

  return (
    <div
      className={styles.page}
    >
      <CcBox
        padding={{ bottom: !tabletUp ? 'none' : 'l' }}
      >
        {children}
      </CcBox>
    </div>
  );
};
