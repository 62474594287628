// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { COLORS } from '../applicationConstants';
import { breakpoints } from './breakpoints';

export const typography: TypographyOptions = {
  fontFamily: '"Lota Grotesque Alt 3", sans-serif',
  fontSize: 18,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,

  h1: {
    color: COLORS.black,
    fontWeight: 400,
    fontSize: 54,
    lineHeight: '70.2px',
    [breakpoints.down('md')]: {
      fontSize: 40,
      lineHeight: '54px',
    },
    [breakpoints.down('sm')]: {
      fontSize: 32,
      color: COLORS.black,
      lineHeight: '36px',
    },
  },
  h2: {
    fontWeight: 400,
    fontSize: 36,
    color: COLORS.black,
    lineHeight: '46.8px',
    [breakpoints.down('md')]: {
      fontSize: '28px',
      lineHeight: '36.4px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '24px',
      color: COLORS.black,
      lineHeight: '32px',
    },
  },
  subtitle1: {
    fontSize: '24px',
    color: COLORS.black,
    lineHeight: '36px',
    fontWeight: 600,
    [breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  subtitle2: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: COLORS.black,
    [breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: '24px',
    color: COLORS.black,
    lineHeight: '36px',
    [breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  body2: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    color: COLORS.black,
    [breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  caption: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
    color: COLORS.black,
    [breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  overline: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '16px',
    color: COLORS.black,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      fontSize: '13px',
      lineHeight: '17px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  button: {
    fontSize: '16px',
    fontWeight: 900,
    lineHeight: '20px',
    color: COLORS.black,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    [breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
};
