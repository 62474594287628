// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js/features/global-this';
import React, {
  lazy,
} from 'react';
import ReactDOM from 'react-dom';

import { MuiThemeProvider } from '@material-ui/core';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import log from 'loglevel';
import packageFile from '../package.json';
import history from './utils/history';
import { createReduxStore } from './store';
import './index.scss';
import { MuiTheme } from './MuiTheme';
import {
  IS_LOCAL, IS_PROD, IS_LOGGING_ENABLED,
} from './applicationConstants/envVariables';

import { endpoint } from './utils/apiRequest';
import Loader from './components/Loader';
import { SuspenseContext } from './contexts/SuspenseContext';
import { ScrollToTopOnRouteChange } from './components/ScrollToTopOnRouteChange';
import { SentryErrorBoundary } from './features/SentryErrorBoundry';
import { getDeviceInfo } from './utils/getDeviceInfo';
import { initiatePolifills } from './polifills';
import { VersionUpdate } from './features/VersionUpdate';

initiatePolifills();

export const AppEntry = lazy(() => import('./entry'));

const getMessageByTarget = (target: any) => {
  if (target.ariaLabel) {
    return target.ariaLabel;
  }
  if (target.tagName.toLowerCase() === 'input') {
    return `[inputName = ${target.name}]`;
  }
  if (target.tagName.toLowerCase() === 'a') {
    return `[Link = ${target.outerText}]`;
  }

  return null;
};

if (!IS_LOCAL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click' && hint) {
        const { target } = hint.event;
        const newMessage = getMessageByTarget(target);
        if (newMessage) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = newMessage;
        }
      }
      return breadcrumb;
    },
    integrations: [
      new Sentry.Integrations.Breadcrumbs(),
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: [endpoint.replace('https://', '')],
      }),
    ],
    release: packageFile.version,
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    ignoreErrors: [
      'Socket server did not execute the callback for setAttributes with data',
    ],
  });
  const deviceInfo = getDeviceInfo();
  Sentry.configureScope((scope) => {
    Object.keys(deviceInfo).forEach((key) => {
      scope.setTag(`device.${key}`, deviceInfo[key]);
    });
  });
}

const store = createReduxStore();

if (IS_PROD) {
  const [body] = document.getElementsByTagName('body');
  body.setAttribute('class', 'disable-default-error');
}

if (IS_LOGGING_ENABLED) {
  log.enableAll();
} else {
  log.setLevel('warn');
}
const container = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={MuiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <SentryErrorBoundary>
            <SuspenseContext>
              <ScrollToTopOnRouteChange />
              <Loader />
              <AppEntry />
              {
                !IS_LOCAL && <VersionUpdate />
              }
            </SuspenseContext>
          </SentryErrorBoundary>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  container,
);
