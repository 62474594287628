import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APPROVING_LOADING_PAGE, SUBMISSION_LOADING_PAGE } from '../../routes';

const loaderContainer = document.getElementById('loader-root');

const Loader = () => {
  const { pathname } = useLocation();
  const loaderStatus = useSelector((state) => state.app.loader);

  useEffect(() => {
    const hideForSpecialLoadingPage = SUBMISSION_LOADING_PAGE.isMatch(pathname)
        || APPROVING_LOADING_PAGE.isMatch(pathname);

    if (!loaderContainer) return () => {};
    if (!loaderStatus || hideForSpecialLoadingPage) {
      loaderContainer.style.display = 'none';
      return () => {};
    }
    const inputElement = loaderContainer.getElementsByTagName('input').item(0);
    if (inputElement) {
      inputElement.focus();
    }

    loaderContainer.style.display = 'flex';

    return () => {
      if (!loaderContainer) return;
      loaderContainer.style.display = 'none';
    };
  }, [loaderStatus, pathname]);

  return null;
};

export default Loader;
