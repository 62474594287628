import React from 'react';
import { createStyles, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CredCompareSVGIcon from '../CredCompareSVGIcons';

const useStyles = makeStyles((theme: Theme) => createStyles({
  closeButton: {
    position: 'absolute',
    right: 40,
    top: 40,
    padding: 0,
    width: 32,
    height: 32,

    [theme.breakpoints.down('xs')]: {
      right: 24,
      top: 24,
    },
  },
}));

type Props = {
    onClose: () => void;
};

export const CloseButton = ({ onClose }: Props) => {
  const styles = useStyles();
  return (
    <IconButton aria-label="[button = close]" className={styles.closeButton} onClick={onClose}>
      <CredCompareSVGIcon width={24} height={24} name="cross" color="rgba(50, 32, 126, 0.5)" />
    </IconButton>
  );
};
